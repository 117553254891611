<style scoped>
.group-list{
    width: 140px;
    flex-shrink: 0;
    border-right:1px solid #E4E7ED;
    margin-right: 10px;
    padding-right: 10px;
}

.media-list {
    display: flex;
    flex-wrap: wrap;
    /* width:500px; */
    /* border-right: 1px solid #F2F6FC;
    border-bottom: 1px solid #F2F6FC; */
    min-height:50vh;
    align-content: flex-start;
}
.media-box{
    width:20%;
    height: 20%;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;
    /* border-top: 1px solid #F2F6FC;
    border-left: 1px solid #F2F6FC; */
    position: relative;
    text-align: center;
}

.media-box .checkbox{
    position: absolute;
    right: 6px;
    top: -4px;
}

.media-box:hover{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    /* border-color: #9da4ac; */
    /* transform: scale(1.1); */
    background: #fff;
}
.media-list .image{
    width: 100px;
    height: 100px;
    object-fit: contain;
}
.media-name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
}

.dialog-footer{
    display: flex;
    justify-content: space-between;
}

.upload-box {
    display: flex;
    align-items: center;
}
.upload-tip{
    font-size:12px;
    color: #E6A23C;
    margin-left:8px;
}
.upload-progress{
    /* max-width:300px; */
    margin-left:8px;
    width: 100px;
}

.upload-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: 100%;
    line-height: 61.8px;
    text-align: center;
}
.upload {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* width: 61.8px;
    height: 61.8px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: block;*/
    /* position: relative;  */
    border: 1px dashed #d9d9d9;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
}
.small .upload{

}

.upload:hover {
    border-color: #409EFF;
}
.del-btn{
    position: absolute;
    top: 2px;
    right:  2px;
    display: none;
}

.upload:hover .del-btn{
    display: block;
}

.upload .upload-preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.group-item{
    margin-bottom:10px;
    cursor: pointer;
}
.group-checked{
    color: #409EFF;
}
.disabled .upload {
    cursor:not-allowed;
}
</style>
<template>
    <div style="display:inline-block;min-width:61.8px;">
        <div @click="showGallery" style="display:flex;align-items:center;width:100%;" :class="{disabled: isDisabled}">
            <slot>
                <div style="display:flex;width:100%;padding-bottom:100%;position:relative;height:0;">
                    <div class="upload">
                        <template v-if="modelValue">
                            <video v-if="type == 'video'" :src="modelValue" class="upload-preview" object-fit="contain" preload2="none"></video>
                            <img v-else-if="type == 'audio'" src="https://static.atomix.kilotree.cn/audio-placeholder.png" style="width: 100%;"/>
                            <img v-else :src="modelValue" class="upload-preview"/>
                        </template>
                        
                        <el-icon v-else><Plus /></el-icon>
                        <el-icon size="22px" class="del-btn" @click.stop="clear" v-if="modelValue"><CircleCloseFilled /></el-icon>
                    </div>
                </div>
            </slot>
        </div>

        <el-dialog
            
            custom-class="gallery-dialog"
            width="800px"
            v-model="visible" append-to-body align-center>

            <template #title>
                <div class="row-align-center">
<!--                     <div class="row-align-center" style="width:140px;margin-right: 10px;">
                        <div>{{type == 'video' ? '选择视频' : '选择图片'}}</div>
                        <el-link type="primary" :href="$router.resolve({
                            name: 'FileList',
                        }).fullPath" target="_blank" style="margin-left:10px" v-if="hasPrivilege('file_admin')">素材管理</el-link>
                    </div> -->
                    <div>
                        <!-- <el-input style="width:140px;"/> -->
                        <el-select style="width:140px;" placeholder="选择分组" v-model="query.group_id" @change="fetchRows" filterable>
                            <el-option v-for="item in group_list" :label="item.group_name" :value="item.group_id" :key="item.group_id"
                            ></el-option>
                        </el-select>
                    </div>
                    <div style="margin-left:10px;" class="row-align-center">
                        <el-input v-model="query.keyword" placeholder="输入关键词" @keyup.enter="search" clearable style="width:168px;"/>
                        <el-button type="primary" plain style="margin-left:10px;" @click="search">搜索</el-button>
                    </div>
                </div>
            </template>

            <div style="height:61.8vh;overflow:hidden;display:flex;align-items:stretch;">
<!--                  <el-scrollbar class="group-list">
                    <div v-for="item in group_list" :key="item.group_id" @click="clickGroup(item)"
                        class="group-item ellipsis" :class="{'group-checked': item.group_id == query.group_id}">
                        {{item.group_name}}
                    </div>
                </el-scrollbar>
 -->
                <div style="flex:1;" class="column">
                    <el-scrollbar style="flex:1;">
                        <div class="media-list" v-if="rows.length > 0">
                            <template v-for="item in rows" :key="item.file_id">
                                <div class="media-box" @click="handleChange(item)">
                                    <el-checkbox v-if="item.checked"
                                        v-model="item.checked" class="checkbox" @change="handleChange(item)"></el-checkbox>
                                        
                                    <!-- <video preload :src="item.file_url" 
                                        :poster="item.video_poster"
                                        class="image" object-fit="contain" v-if="item.file_type == 'video'"/> -->

                                    <img fit="contain" :src="item.preview_image" class="image"/>

                                    <div class="media-name">{{item.file_name}}</div>
                                </div>
                            </template>
                        </div>

                        <el-empty v-else description="暂无数据" style="width:100%;"></el-empty>
                    </el-scrollbar>

                    <div style="display:flex;justify-content:space-between;margin-top:18px;">
                        <div class="upload-box" v-if="hasPrivilege('file_admin')">
                            <template v-if="type == 'video'">
                                <UploadFile @success="uploadOk" multiple accept="video/mp4">
                                    <el-button type="primary" icon2="el-icon-upload" plain >点击上传</el-button>
                                </UploadFile>
                                <!-- <el-upload
                                    :show-file-list="false"
                                    :action="uploadUrl"
                                    :multiple = "false"
                                    accept="video/mp4"
                                    :before-upload="beforeUpload"
                                    :on-success = "uploadOk"
                                    :on-error = "handleError"
                                    :data="upload_params"
                                    :on-progress="handleProgressChange"
                                    :with-credentials="withCredentials">
                                
                                    <el-button :loading="loading" type2="primary">点击上传</el-button>
                                            
                                </el-upload>
            
                                <el-progress v-if="show_progress" 
                                    class="upload-progress" :text-inside="true" :stroke-width="16" :percentage="percentage"></el-progress> -->

                                <div class="upload-tip">10M以内MP4格式视频</div>
                            </template>

                            <template v-else-if="type == 'audio'">
                                <UploadFile @success="uploadOk" multiple accept="audio/mpeg">
                                    <el-button type="primary" icon2="el-icon-upload" plain >点击上传</el-button>
                                </UploadFile>
                            </template>

                            <template v-else-if="type == 'document'">
                                <UploadFile @success="uploadOk" :data="{
                                    group_id: query.group_id,
                                }" multiple accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint,
text/plain, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation">
                                    <el-button type="primary" plain>点击上传</el-button>
                                </UploadFile>
                                <div class="upload-tip">2M以内doc/docx/excel/pdf/ppt</div>
                            </template>

                            <template v-else>
                                <UploadFile @success="uploadOk" :data="{
                                    group_id: query.group_id,
                                }" multiple>
                                    <el-button type="primary" plain>点击上传</el-button>
                                </UploadFile>
                                <div class="upload-tip">2M以内png/jpg/gif格式图片</div>
                            </template>

                        </div>

                        <el-pagination hide-on-single-page2
                            @current-change="handleCurrentChange"
                            :current-page="query.page"
                            :page-size="query.page_size"
                            layout="total, prev, next, jumper"
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="row-between">
                    <div>
                        <el-button @click="showDialog({
                            title: '分组信息',
                            type: 'add_group',
                            form_url: '/admin/main/group/save_group',
                            form:{
                                group_type: this.type,
                            }
                        })" v-if="hasPrivilege('file_group_admin')">添加分组</el-button>
                        <el-link type="primary" :href="$router.resolve({
                            name: 'FileList',
                        }).fullPath" target="_blank" style="margin-left:10px" v-if="hasPrivilege('file_admin')">素材管理</el-link>
                    </div>

                    <div>
                        <div>
                            <el-button style="width:88px;" type="primary" @click="handleOk">确 定</el-button>
                            <el-button style="width:88px;" @click="visible = false">取 消</el-button>
                        </div>
                    </div>
                </div>
            </template>
            <!-- <div slot="title">
                上传图片
            </div> -->
        </el-dialog>

        <!-- <el-dialog
            title="视频设置"
            v-model="video_dialog.visible">
                <el-form label-width="80px" size="small">
                    <el-form-item label=" ">
                        <video :poster="video_dialog.media_info.video_poster"
                            :src="video_dialog.media_info.file_url"
                            controls ="video_dialog.media_info.file_url" style="width:200px;height:200px;" object-fit="contain"/>
                    </el-form-item>

                <el-form-item label="视频标题">
                    <el-input v-model="video_dialog.media_info.video_title" style="max-width:200px"/>
                </el-form-item>

                <el-form-item label="视频封面">
                    <UploadFile v-model="video_dialog.media_info.video_poster" :data="{
                        group_id: query.group_id,
                    }">
                        <el-button size="small" type="primary" icon2="el-icon-upload">点击上传</el-button>
                    </UploadFile>
                </el-form-item>

            </el-form>

            <template #footer>
                <el-button style="width:88px;" @click="video_dialog.visible = false">取 消</el-button>
                <el-button style="width:88px;" type="primary" @click="updateVideoInfo">保 存</el-button>
            </template>
        </el-dialog> -->

        <Dialog v-model="dialog" @save="saveDialog">
            <el-form>
                <template v-if="dialog.type == 'add_group'">
                    <el-form-item label="分组名称">
                        <el-input v-model="dialog.form.group_name" maxlength="10" show-word-limit clearable placeholder="输入分组名称"/>
                    </el-form-item>
                </template>
            </el-form>
        </Dialog>

    </div>
    <div style="width:100%;" v-if="tip">{{tip}}</div>
</template>

<script>

import {http, BASE_URL} from '@/plugin/http'
const uploadUrl = process.env.NODE_ENV == 'development' ? BASE_URL + '/admin/main/file/upload_video' : 'https://up-z2.qiniup.com';

import { defineAsyncComponent, reactive } from 'vue'
import {
  useFormDisabled,
} from 'element-plus'

export default {
    components:{
        UploadFile: defineAsyncComponent(() => import('@/component/main/UploadFile')),
    },
    expose:['showGallery'],
    // inject: ['elForm'],
    data: () => ({
        isDisabled: reactive(useFormDisabled()),
        dialog:{
            visible: false,
            form:{}
        },
        visible: false,
        query:{
            page: 1,
            page_size: 20,
            type: 'image',
            group_id: 0,
        },
        rows:[],
        group_list:[],
        total: 0,

        uploadUrl: uploadUrl,
        withCredentials: process.env.NODE_ENV == 'development',
        upload_params: {},
        loading: false,
        percentage: 0,
        show_progress: false,
        checked_list: []

        // video_dialog:{
        //     visible: false,
        //     media_info:{}
        // },
    }),
    watch:{
        show(){
            console.log('gallery show')
            this.visible = this.show
        },
        visible(){
            if(this.visible) {
                this.query.file_type = this.type
                this.fetchRows();
                this.fetchGroupList();
            }
        }
    },
    created(){
        // this.query.file_type = this.type
        // this.fetchRows();
        // this.fetchGroupList();
    },
    updated(){

    },
    methods:{
        showDialog(opt){
            Object.assign(this.dialog, opt || {})
            this.dialog.visible = true
        },
        saveDialog(){
            http.post(this.dialog.form_url, this.dialog.form)
            .then(() => {
                this.dialog.visible = false
                this.fetchGroupList();
            }).catch(this.$handleError)
        },
        showGallery(){
            if(this.isDisabled) {
                return
            }

            this.visible = true
            // this.fetchRows();
        },
        
        handleChange(item){
            // console.log('changed');
            // this.multiple = true

            let old_checked = item.checked
            if(this.multiple != true) {
                this.rows.map(v => v.checked = false)
            }

            item.checked = !old_checked
            let idx = this.checked_list.map(v => v.file_id).indexOf(item.file_id)

            console.log('this.checked_list', idx, item.file_id, this.checked_list)
            if(item.checked) {
                this.checked_list.push(item)
            }else{
                if(idx != -1) {
                    this.checked_list.splice(idx, 1);
                }
            }
        },
        uploadOk(res){
            // console.log('upload okk', res)
            this.loading = false;
            this.show_progress = false;

            if(res.error_code) {
                this.$message.error(res.error_msg);
                return
            }

            let data = res.data
            
            data['checked'] = true
            try{
                // this.rows.splice(this.query.page_size - 1, 1)
                // this.rows.unshift(data)
                // this.fetchRows();

                this.fetchRows({
                    page: 1,
                });
            }catch(e) {
                console.log('kkkk', e)
            }

            // 提示设置封面
            // if(data && data.media_type == 'video') {
            //     this.video_dialog.visible = true;
            //     this.video_dialog.media_info = data
            // }
        },
        handleError(){
            this.loading = false;
            this.show_progress = false;
            this.$message.error('上传失败～');
        },
        handleOk(){
            let checked_list = this.checked_list
            // checked_list = this.rows.filter(v => v.checked)
            // let media_id_arr = []
            
            if(checked_list.length > 0) {
                let value = this.multiple ? checked_list.map(v => v.file_url) : checked_list[0].file_url
                // this.$emit('update:modelValue', value)
                this.updateModelValue(value)

                checked_list.forEach(v => {
                    this.$emit('success', v)
                })
            }

            http.post('/admin/main/file/mark_use', {
                media_id: checked_list.map(v => v.media_id),
                type: this.type,
            });

            this.visible = false
            this.checked_list = []
        },
        clear(){
            this.updateModelValue('');
        },
        updateModelValue(value){
            this.$emit('update:modelValue', value);
            this.$emit('change', value)
        },
        handleCurrentChange(page){
            this.query.page = page;
            this.fetchRows();
        },
        clickGroup(item){
            this.query.group_id = item.group_id
            this.fetchRows({
                page: 1,
            });
        },
        fetchGroupList(){
            http.get('/admin/main/group/group_list', {
                group_type: this.type,
                page: 1,
                page_size: 1000,
            }).then(re => {
                let data = re.data
                this.group_list = data.rows
                this.group_list.unshift(...[
                    {
                        group_id: 0,
                        group_name: '全部',
                    },
                    {
                        group_id: -1,
                        group_name: '未分组',
                    }
                ])
            })
        },
        fetchRows(opt){
            Object.assign(this.query, opt || {})
            http.get('/admin/main/file/file_list', this.query).then((re) => {
                // console.log('re, query', re, this.query)
                let data = re.data
                this.rows = data.rows.map((v) => {
                    v['checked']= false
                    return v
                });
                
                this.total = data.total
                // this.group_list = data.group_list
            });
        },
        search(){
            this.fetchRows({
                page: 1,
            });
        },

        handleProgressChange: function(e){
            this.percentage = Math.round(e.percent*100)/100;
        },
        beforeUpload: function(){
            this.loading = true; 
            this.show_progress = true;
            this.percentage = 0;
        
            const that = this
            return new Promise(function(resole, reject){
                http.get('/upload/get_token.json').then(function(re){
                    const data = re.data
                    that.upload_params['token'] = data.token;
                    resole();
                }).catch(reject);
            });
        },
        updateVideoInfo(){
            http.post('/api/media/update_video_info.json', {
                video_id: this.video_dialog.media_info.media_id,
                video_poster: this.video_dialog.media_info.video_poster,
                video_title: this.video_dialog.media_info.video_title,
            }).then(() => {
                this.video_dialog.visible = false
            }).catch((e) => {
                this.$message.error(e)
            })
        },
        del(){
            this.value = ''
            this.$emit('input', '')
        }
    },
    props:{
        multiple:{
            type: Boolean,
        },
        type: {
            type: String,
            default: 'image',
        },
        modelValue:{
            type: String,
        },
        tip:{
            type: String,
        },
        size:{
            type: String,
        }
        // auth:{
        //     type: Boolean,
        //     default: true,
        // }
        // show:{
        //     type: Boolean,
        // }
        // clearable
    }
}
</script>